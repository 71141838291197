function concatArrays(a: Uint8Array, b: Uint8Array) {
  var c = new Uint8Array(a.length + b.length);
  c.set(a, 0);
  c.set(b, a.length);
  return c;
}

export class EncryptedFile {
  private password: string;
  private encryptedBytes: Uint8Array;
  private initialized: boolean;
  private key!: CryptoKey;
  private ivbytes!: ArrayBuffer;

  constructor(password: string) {
    this.password = password;
    this.encryptedBytes = new Uint8Array();
    this.initialized = false;
  }

  private async tryInitializeCipher(): Promise<void> {
    if (this.initialized) {
      return;
    }

    var pbkdf2iterations = 10000;
    var passphrasebytes = new TextEncoder().encode(this.password);
    var pbkdf2salt = window.crypto.getRandomValues(new Uint8Array(8));

    var passphrasekey = await window.crypto.subtle.importKey(
      "raw",
      passphrasebytes,
      { name: "PBKDF2" },
      false,
      ["deriveBits"]
    );
    console.log("passphrasekey imported");

    var pbkdf2bytes = await window.crypto.subtle.deriveBits(
      {
        name: "PBKDF2",
        salt: pbkdf2salt,
        iterations: pbkdf2iterations,
        hash: "SHA-256",
      },
      passphrasekey,
      384
    );
    console.log("pbkdf2bytes derived");
    pbkdf2bytes = new Uint8Array(pbkdf2bytes);

    let keybytes = pbkdf2bytes.slice(0, 32);
    this.ivbytes = pbkdf2bytes.slice(32);

    this.key = await window.crypto.subtle.importKey(
      "raw",
      keybytes,
      { name: "AES-CBC", length: 256 },
      false,
      ["encrypt"]
    );
    console.log("key imported");

    const resultbytes = new Uint8Array(16);
    resultbytes.set(new TextEncoder().encode("Salted__"));
    resultbytes.set(pbkdf2salt, 8);

    this.initialized = true;
    this.encryptedBytes = resultbytes;
  }

  public async appendBytes(plaintextbytes: Uint8Array): Promise<void> {
    await this.tryInitializeCipher();
    console.log("cipher initialized", this.ivbytes);
    const cipherbytes = await window.crypto.subtle.encrypt(
      { name: "AES-CBC", iv: this.ivbytes },
      this.key,
      plaintextbytes
    );

    console.log("plaintext encrypted");
    const cipherbytesU8 = new Uint8Array(cipherbytes);

    this.encryptedBytes = concatArrays(this.encryptedBytes, cipherbytesU8);
  }

  getBlob(): Blob {
    return new Blob([this.encryptedBytes]);
  }
}

export class DecryptedFile {
  private password: string;
  private encryptedBytes: Uint8Array;
  private decryptedBytes: Uint8Array;
  private initialized: boolean;
  private key!: CryptoKey;
  private ivbytes!: ArrayBuffer;

  constructor(password: string) {
    this.password = password;

    this.encryptedBytes = new Uint8Array();
    this.decryptedBytes = new Uint8Array();
    this.initialized = false;
  }

  private async tryInitializeCipher(): Promise<void> {
    if (this.initialized) {
      return;
    }

    var pbkdf2iterations = 10000;
    var passphrasebytes = new TextEncoder().encode(this.password);
    var pbkdf2salt = this.encryptedBytes.slice(8, 16);

    var passphrasekey = await window.crypto.subtle.importKey(
      "raw",
      passphrasebytes,
      { name: "PBKDF2" },
      false,
      ["deriveBits"]
    );
    console.log("passphrasekey imported");

    var pbkdf2bytes = await window.crypto.subtle.deriveBits(
      {
        name: "PBKDF2",
        salt: pbkdf2salt,
        iterations: pbkdf2iterations,
        hash: "SHA-256",
      },
      passphrasekey,
      384
    );
    console.log("pbkdf2bytes derived");
    pbkdf2bytes = new Uint8Array(pbkdf2bytes);

    let keybytes = pbkdf2bytes.slice(0, 32);
    this.ivbytes = pbkdf2bytes.slice(32);

    this.key = await window.crypto.subtle.importKey(
      "raw",
      keybytes,
      { name: "AES-CBC", length: 256 },
      false,
      ["decrypt"]
    );
    console.log("key imported");

    this.initialized = true;
  }

  public async appendBytes(cipherbytesU8: Uint8Array): Promise<void> {
    this.encryptedBytes = concatArrays(this.encryptedBytes, cipherbytesU8);
    await this.tryInitializeCipher();
    console.log(this.encryptedBytes);
    var plaintextbytes = await window.crypto.subtle.decrypt(
      { name: "AES-CBC", iv: this.ivbytes },
      this.key,
      this.encryptedBytes.slice(16)
    );
    // var plaintextbytes2 = await window.crypto.subtle.decrypt(
    //   { name: "AES-CBC", iv: this.ivbytes },
    //   this.key,
    //   this.encryptedBytes.slice(64)
    // );
    // const laintextbytesFinal = concatArrays(
    //   new Uint8Array(plaintextbytes),
    //   new Uint8Array(plaintextbytes2)
    // );

    this.decryptedBytes = new Uint8Array(plaintextbytes);
  }

  getBlob(): Blob {
    return new Blob([this.decryptedBytes]);
  }
}
