import {
  Box,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  ListItemButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Alert,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import {
  connectDatabaseEmulator,
  getDatabase,
  ref,
  set,
  get,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
} from "firebase/database";
import * as AES from "crypto-js/aes";
import { Link } from "react-router-dom";
import { useAccount } from "../context/AccountProvider";

import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import AddIcon from "@mui/icons-material/Add";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const slugify = (text: string) =>
  text
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-");

const db = getDatabase();
// eslint-disable-next-line no-restricted-globals
if (location.hostname === "localhost") {
  // Point to the RTDB emulator running on localhost.
  connectDatabaseEmulator(db, "127.0.0.1", 9000);
}
export function DashboardPage() {
  const { user } = useAccount();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const navigate = useNavigate();
  useEffect(() => {}, []);
  const [albumName, setAlbumName] = useState("");
  const [albumPassword, setAlbumPassword] = useState("");

  const createAlbum = async () => {
    try {
      const id = slugify(albumName);

      if (albumName && id && albumPassword) {
        const currentData = await get(ref(db, "albums/" + id));
        if (!currentData.exists()) {
          await set(ref(db, "albums/" + id), {
            name: albumName,
            encryptedName: AES.encrypt(albumName, albumPassword).toString(),
            createdAt: Date.now(),
          });
          setAlbumName("");
          setAlbumPassword("");
          enqueueSnackbar("Album created", { variant: "success" });
        } else {
          throw new Error("Album already exists");
        }
      } else {
        throw new Error("Please fill all the fields");
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  const [albums, setAlbums] = useState<Record<string, any>>({});
  console.log("albums", albums);
  useEffect(() => {
    const albumsRef = ref(db, "albums");

    onChildAdded(albumsRef, (data) => {
      console.log("child added", data.key, data.val());
      setAlbums((albums) => ({
        ...albums,
        [data.key as string]: data.val(),
      }));
      // addCommentElement(postElement, data.key, data.val().text, data.val().author);
    });

    onChildChanged(albumsRef, (data) => {
      console.log("child changed", data.key, data.val());
      setAlbums((albums) => ({
        ...albums,
        [data.key as string]: data.val(),
      }));
    });

    onChildRemoved(albumsRef, (data) => {
      setAlbums((albums) => {
        delete albums[data.key as string];
        return { ...albums };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {user && (
        <Box sx={{ flexGrow: 1, margin: "16px" }}>
          <div id="create-album-container">
            <Box sx={{ display: "flex", flexGrow: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  flexDirection: "column",
                  marginRight: "16px",
                }}
              >
                <TextField
                  label="Album Name"
                  variant="outlined"
                  size="small"
                  value={albumName}
                  onChange={(e) => setAlbumName(e.target.value)}
                  sx={{ marginBottom: "8px" }}
                />
                <TextField
                  label="Album Password"
                  type="password"
                  variant="outlined"
                  size="small"
                  value={albumPassword}
                  onChange={(e) => setAlbumPassword(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton color="primary" onClick={createAlbum}>
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
          </div>
        </Box>
      )}

      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {Object.keys(albums).map((albumId) => (
          <ListItem key={albumId} disablePadding>
            <ListItemButton onClick={() => navigate(`/${albumId}`)}>
              <ListItemAvatar>
                <Avatar>
                  <ImageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={albums[albumId].name}
                secondary={new Date(
                  albums[albumId].createdAt || 0
                ).toLocaleDateString()}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
