import { createTheme } from "@mui/material/styles";

import { green, purple } from "@mui/material/colors";

export const theme = () => {
  const themeOptions = {
    palette: {
      primary: {
        main: "#4c0458",
      },
      secondary: {
        main: green[500],
      },
      background: {
        default: "#fff",
      },
    },
  };

  return createTheme(themeOptions);
};

export default theme;
