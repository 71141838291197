import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Outlet, useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import MenuIcon from "@mui/icons-material/Menu";
import { useAccount } from "../context/AccountProvider";
import { useState } from "react";

export const MainLayout = () => {
  const { fetching, user, signOut, signInWithGoogleHandler } = useAccount();
  const [title, setTitle] = useState("Encrypted Albums");
  let { fileName } = useParams();

  console.log(user);
  return (
    <>
      {!fileName && (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {title}
              </Typography>
              {!fetching &&
                (!!user ? (
                  <>
                    <Avatar
                      alt={user?.email || user?.email?.[0]}
                      src={user?.photoURL || ""}
                    />
                    <Button color="inherit" onClick={() => signOut()}>
                      Logout
                    </Button>
                  </>
                ) : (
                  <Button
                    color="inherit"
                    onClick={() => signInWithGoogleHandler()}
                  >
                    Login
                  </Button>
                ))}
            </Toolbar>
          </AppBar>
        </Box>
      )}
      <main>
        <Outlet context={{ setTitle }} />
      </main>
    </>
  );
};
