/* eslint-disable no-restricted-globals */
import {
  Button,
  Box,
  Paper,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  ListItemButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Alert,
  Avatar,
  IconButton,
  LinearProgress,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import CryptoJS from "crypto-js";
import {
  connectDatabaseEmulator,
  getDatabase,
  get,
  ref,
} from "firebase/database";
import {
  getStorage,
  connectStorageEmulator,
  ref as storageRef,
  uploadBytesResumable,
  listAll,
  getMetadata,
  getBlob,
  getBytes,
} from "firebase/storage";
import { DragDropFile } from "../components/DragDropFile";
import * as mime from "mime";
import secureLocalStorage from "react-secure-storage";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { useAccount } from "../context/AccountProvider";
import HttpsIcon from "@mui/icons-material/Https";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import DownloadIcon from "@mui/icons-material/Download";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import QRCodeStyling, {
  DrawType,
  TypeNumber,
  Mode,
  ErrorCorrectionLevel,
  DotType,
  CornerSquareType,
  CornerDotType,
  Options,
} from "qr-code-styling";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { DecryptedFile, EncryptedFile } from "../CryptoFileService";

const db = getDatabase();
// eslint-disable-next-line no-restricted-globals
if (location.hostname === "localhost") {
  // Point to the RTDB emulator running on localhost.
  connectDatabaseEmulator(db, "127.0.0.1", 9000);
}

const storage = getStorage();
// eslint-disable-next-line no-restricted-globals
if (location.hostname === "localhost") {
  // Point to the Storage emulator running on localhost.
  connectStorageEmulator(storage, "127.0.0.1", 9199);
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

type ContextType = { setTitle: any };

export function AlbumPage() {
  let { albumId, fileName } = useParams();
  const [album, setAlbum] = useState<any>(null);
  const [qrCode, setQrCode] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [uploadProgress, setUploadProgress] = useState<Record<string, any>>({});
  const [albumPassword, setAlbumPassword] = useState("");
  const [correctPassword, setCorrectPassword] = useState<any>(null);
  const { setTitle } = useOutletContext<ContextType>();
  const [files, setFiles] = useState<any>({});
  const [notFound, setNotFound] = useState<boolean>(false);
  const [filesContent, setFilesContent] = useState<any>({});
  const { user } = useAccount();
  const navigate = useNavigate();

  const localStorageKey = `ALBUM_PASSWORD_${albumId}`;
  useEffect(() => {
    const localAlbumPassword = secureLocalStorage.getItem(localStorageKey);
    if (localAlbumPassword) {
      setAlbumPassword(localAlbumPassword as string);
    }
  }, [localStorageKey]);

  const [refQrCode, setRefQrCode] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (refQrCode && qrCode) {
      qrCode.append(refQrCode);
    } else if (refQrCode && !qrCode) {
      refQrCode.innerHTML = "";
    }
  }, [qrCode, refQrCode]);

  console.log("refQrCode", refQrCode);
  const updateFileList = () => {
    const albumRef = ref(db, `albums/${albumId}`);
    const albumStorageRef = storageRef(storage, `albums/${albumId}`);

    get(albumRef).then((snapshot) => {
      if (snapshot.exists()) {
        console.log(snapshot.val());
        setAlbum(snapshot.val());
      } else {
        setNotFound(true);
        console.log("No data available");
      }
    });

    listAll(albumStorageRef)
      .then((res) => {
        res.items.forEach(async (itemRef) => {
          const metadata = await getMetadata(itemRef);
          console.log(metadata);
          setFiles((files: any) => ({
            ...files,
            [metadata.name]: { metadata, itemRef },
          }));
        });
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
  };

  const updateFileContent = async (fileName: string) => {
    if (fileName && files[fileName]) {
      try {
        const itemRef = files[fileName].itemRef;

        if (correctPassword) {
          const buffer = await getBytes(itemRef);

          const decryptedFile = new DecryptedFile(correctPassword);
          await decryptedFile.appendBytes(new Uint8Array(buffer.slice(0)));
          const fileDecBlob = decryptedFile.getBlob();

          var fileDec = new File([fileDecBlob], fileName); // Create blob from typed array
          setFilesContent((files: any) => ({
            ...files,
            [fileName as string]: fileDec,
          }));
          return fileDec;
        }
      } catch (error: any) {
        console.log(error);
        enqueueSnackbar(`Error downloading ${fileName}: ${error.message}`, {
          variant: "error",
        });
      }
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    updateFileContent(fileName as string);
  }, [fileName, files, correctPassword]);

  useEffect(() => {
    updateFileList();
  }, [albumId, correctPassword]);

  useEffect(() => {
    if (album && !correctPassword) {
      try {
        const decryptedName = CryptoJS.AES.decrypt(
          album.encryptedName,
          albumPassword
        ).toString(CryptoJS.enc.Utf8);

        console.log(decryptedName);
        if (decryptedName == album.name) {
          console.log("Correct password");
          setCorrectPassword(albumPassword);
          secureLocalStorage.setItem(localStorageKey, albumPassword);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [album, albumPassword, correctPassword, localStorageKey]);

  useEffect(() => {
    if (album && setTitle) {
      setTitle(album.name);
    }
  }, [album, setTitle]);

  const handleEncryptedUpload = (files: FileList) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      file.arrayBuffer().then(async (buffer) => {
        const encryptedFile = new EncryptedFile(correctPassword);
        await encryptedFile.appendBytes(new Uint8Array(buffer));
        const fileEnc = encryptedFile.getBlob();

        const fileRef = storageRef(storage, `albums/${albumId}/${file.name}`);
        const uploadTask = uploadBytesResumable(fileRef, fileEnc);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress((data) => ({ ...data, [file.name]: progress }));
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            setUploadProgress((data) => {
              delete data[file.name as string];
              return { ...data };
            });
            console.error(error);
            enqueueSnackbar(`Error uploading ${file.name}: ${error.message}`, {
              variant: "error",
            });
          },
          () => {
            setTimeout(() => {
              setUploadProgress((data) => {
                delete data[file.name as string];
                return { ...data };
              });
            }, 5000);
            console.log("Uploaded a blob or file!", file.name);
            enqueueSnackbar(`File uploaded: ${file.name}`, {
              variant: "success",
            });
            updateFileList();
          }
        );
      });
    }
  };

  const handleOpenQrCodeFor = (file: string) => async () => {
    const qrCode = new QRCodeStyling({
      width: 1500,
      height: 1500,
      margin: 50,
      type: "canvas",
      data: `${window.location.origin}/${albumId}/${encodeURIComponent(file)}`,
      image: "/play_circle_filled.svg",
      dotsOptions: {
        color: "#4c0458",
        type: "rounded",
      },
      backgroundOptions: {
        color: "#ffffff",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        hideBackgroundDots: true,
        imageSize: 0.2,
        margin: 0,
      },
      cornersSquareOptions: {
        type: "extra-rounded",
        color: "#2b0032",
      },
      qrOptions: {
        errorCorrectionLevel: "Q",
      },
      cornersDotOptions: { color: "#2b0032" },
    });

    setSelectedFile(file);
    setQrCode(qrCode);
  };

  return (
    <div>
      {notFound && (
        <Box sx={{ flexGrow: 1, margin: "16px" }}>
          <div id="album-not-found-container">
            <Typography variant="h6">Album not found</Typography>
            <Typography variant="body1">
              Please check if the link is correct
            </Typography>
          </div>
        </Box>
      )}
      <Dialog open={!!qrCode} onClose={() => setQrCode(null)}>
        <DialogTitle>QR code</DialogTitle>
        <DialogContent sx={{ overflow: "hidden", maxHeight: "300px" }}>
          <div id="qr-code-container" ref={(newRef) => setRefQrCode(newRef)} />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setQrCode(null)}>Ok</Button>
          <Button
            onClick={() => {
              qrCode.download({
                name:
                  `${selectedFile.replace(/\.[^/.]+$/, "")}_qr` || "qr-code",
                extension: "png",
              });
            }}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
      {album && !correctPassword && (
        <Dialog open>
          <DialogTitle>Password Required</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To view files in this Album you need to provide decryption
              password. Password will be stored on your device.
            </DialogContentText>

            <TextField
              label="Album Password"
              autoFocus
              margin="dense"
              type="password"
              fullWidth
              variant="standard"
              size="small"
              value={albumPassword}
              onChange={(e) => setAlbumPassword(e.target.value)}
            />
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() =>
                enqueueSnackbar("Invalid password", { variant: "error" })
              }
            >
              Ok
            </Button>
            <Button onClick={() => history.back()}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}

      {fileName ? (
        <>
          {fileName && !filesContent[fileName] && (
            <Box
              sx={{
                width: "100vw",
                height: "100vh",
                background: "#0e0e0e",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress size={60} sx={{ color: "white" }} />
              {files[fileName] && (
                <Typography sx={{ color: "white", marginTop: "16px" }}>
                  Downloading... {humanFileSize(files[fileName].metadata.size)}
                </Typography>
              )}
            </Box>
          )}
          {fileName && filesContent[fileName] && (
            <>
              <Box
                sx={{
                  width: "100vw",
                  height: "100vh",
                  maxWidth: "100vw",
                  maxHeight: "100vh",
                  background: "#0e0e0e",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {mime.getType(fileName)?.includes("video") ? (
                  <video
                    id="videoPlayer"
                    controls
                    autoPlay
                    muted
                    src={URL.createObjectURL(filesContent[fileName])}
                  >
                    Video
                  </video>
                ) : mime.getType(fileName)?.includes("image") ? (
                  <img
                    id="imageViewer"
                    alt={fileName}
                    style={{
                      maxHeight: "100vh",
                      maxWidth: "100vw",
                    }}
                    src={URL.createObjectURL(filesContent[fileName])}
                  />
                ) : (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <ImageNotSupportedIcon
                      sx={{
                        maxWidth: "200px",
                        color: "white",
                        marginBottom: "16px",
                        fontSize: "200px",
                      }}
                    />
                    <Button
                      onClick={() => {
                        const fileData = filesContent[fileName as string];
                        var a = document.createElement("a");
                        var url = window.URL.createObjectURL(fileData);
                        a.href = url;
                        a.download = fileName as string;
                        a.click();
                        window.URL.revokeObjectURL(url);
                      }}
                      variant="contained"
                    >
                      Download
                    </Button>
                  </Box>
                )}
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          {correctPassword && user && (
            <Box sx={{ margin: "16px" }}>
              <DragDropFile handleFile={handleEncryptedUpload} />
            </Box>
          )}
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {Object.keys(uploadProgress).map((fileName) => (
              <ListItem
                key={fileName}
                sx={{
                  background:
                    uploadProgress[fileName] >= 100 ? "#dcedd0" : "#fff",
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    {uploadProgress[fileName] >= 100 ? (
                      <CloudDoneIcon />
                    ) : (
                      <CloudUploadIcon />
                    )}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  style={{ paddingRight: "12px" }}
                  primary={fileName}
                  secondary={
                    <LinearProgress
                      variant="determinate"
                      value={uploadProgress[fileName]}
                    />
                  }
                />
              </ListItem>
            ))}
          </List>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {Object.keys(files).map((fileName) => (
              <ListItem
                key={fileName}
                disablePadding
                secondaryAction={
                  <>
                    <IconButton
                      aria-label="qrCode"
                      onClick={handleOpenQrCodeFor(fileName)}
                    >
                      <QrCode2Icon />
                    </IconButton>
                    <IconButton
                      aria-label="download"
                      onClick={async () => {
                        try {
                          let fileData = filesContent[fileName];
                          if (!fileData) {
                            fileData = await updateFileContent(fileName);
                          }
                          var a = document.createElement("a");
                          var url = window.URL.createObjectURL(fileData);
                          a.href = url;
                          a.download = fileName as string;
                          a.click();
                          window.URL.revokeObjectURL(url);
                        } catch (err: any) {
                          console.log(err);
                          enqueueSnackbar(
                            `Error downloading file: ${err.message}`,
                            { variant: "error" }
                          );
                        }
                      }}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </>
                }
              >
                <ListItemButton
                  style={{ paddingRight: "96px" }}
                  onClick={() => navigate(`/${albumId}/${fileName}`)}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <HttpsIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={fileName}
                    secondary={
                      <>
                        {humanFileSize(files[fileName].metadata.size)} ---{" "}
                        {new Date(
                          files[fileName].metadata.updated || 0
                        ).toLocaleString()}
                      </>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </div>
  );
}
