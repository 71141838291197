import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBMMfPA44TDQdvFfJijeHfyEhz3umYoSug",
  authDomain: "encrypted-albums.firebaseapp.com",
  databaseURL:
    "https://encrypted-albums-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "encrypted-albums",
  storageBucket: "encrypted-albums.appspot.com",
  messagingSenderId: "89307050722",
  appId: "1:89307050722:web:4eea068b6b778650e7e4bb",
};

export const app = initializeApp(firebaseConfig);
