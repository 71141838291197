import { User } from "firebase/auth";
import { Context, ReactNode, createContext, useContext, useState } from "react";

import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signOut as signOutHandler,
} from "firebase/auth";
import { app } from "../firebase";

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export interface AccountProviderState {
  user: User | null;
  fetching: boolean;
  signInWithGoogleHandler: () => Promise<any>;
  signOut: () => Promise<any>;
}

const AccountProviderContext: Context<AccountProviderState> =
  createContext<AccountProviderState>({} as AccountProviderState);

interface AccountProviderProps {
  children: ReactNode;
}

/**
 * This context and hook are used because XDEFI provider is injected dynamically after page is loaded,
 * so we cannot statically load it from the window object like we did for others providers.
 * The hook can be used in any place where we need to activate XDEFI connector.
 */

export const AccountProvider = ({ children }: AccountProviderProps) => {
  const [user, setUser] = useState(auth.currentUser);
  const [fetching, setFetching] = useState(true);

  onAuthStateChanged(auth, (user) => {
    setFetching(false);
    setUser(user);
  });
  const signInWithGoogleHandler = async () => {
    await signInWithPopup(auth, provider);
  };
  const signOut = async () => {
    await signOutHandler(auth);
  };

  return (
    <AccountProviderContext.Provider
      value={{ user, fetching, signInWithGoogleHandler, signOut }}
    >
      {children}
    </AccountProviderContext.Provider>
  );
};

export const useAccount = (): AccountProviderState => {
  return useContext(AccountProviderContext);
};
