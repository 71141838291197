import React, { useEffect } from "react";
import { Routes as RoutesWrapper, Route, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAccount } from "../context/AccountProvider";
import { LoginPage } from "../pages/LoginPage";
import { DashboardPage } from "../pages/DashboardPage";
import { MainLayout } from "../layout/MainLayout";
import { AlbumPage } from "../pages/AlbumPage";

function Routes() {
  return (
    <RoutesWrapper>
      <Route path="/" element={<MainLayout />}>
        <Route path="/" element={<DashboardPage />}></Route>
        <Route path="/:albumId/:fileName?" element={<AlbumPage />}></Route>
      </Route>

      <Route path="/*" element={<Navigate to="/" />} />
    </RoutesWrapper>
  );
}

export default Routes;
