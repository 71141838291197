import React from "react";
import "./App.css";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { AccountProvider } from "./context/AccountProvider";

import { NavigationScroll } from "./layout/NavigationScroll";
import theme from "./themes";
import Routes from "./routes";
import { SnackbarProvider } from "notistack";

function App() {
  return (
    <AccountProvider>
      <SnackbarProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme()}>
            <CssBaseline />
            <NavigationScroll>
              <Routes />
            </NavigationScroll>
          </ThemeProvider>
        </StyledEngineProvider>
      </SnackbarProvider>
    </AccountProvider>
  );
}

export default App;
